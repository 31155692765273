import React, { useEffect, useState, useContext } from "react"
import { navigate } from "gatsby"
import { Flex, Box, Text, Image } from "rebass"
import { useForm } from "react-hook-form"
import qs from "qs"

import { LocalDevContext } from "../context/local-dev"
import { AccountContext } from "../context/account"
import LoginLayout from "../components/login-layout"
import Button from "../components/button"
import CodeSnippet from "../components/code-snippet"
import SEO from "../components/seo"
import Spinner from "../components/spinner"
import Graphic from "../assets/login-graphic.png"

const IndexPage = () => {
  const [error, setError] = useState(false)
  const [status, setStatus] = useState("pending")
  const { setLocalUrl, setLocalToken } = useContext(LocalDevContext)

  useEffect(() => {
    const [_, ...search] = window.location.search
    const res = qs.parse(search)

    if (res.lurl && res.ltoken) {
      setLocalUrl(res.lurl)
      setLocalToken(res.ltoken)
      setStatus("success")
    } else {
      setStatus("error")
      setError("Something went wrong")
    }
  }, [])

  const renderContent = React.useCallback(() => {
    switch (status) {
      case "pending":
        return (
          <Box>
            <Text mb={4} fontWeight="bold" fontSize={4}>
              Linking...
            </Text>
            <Flex justifyContent="center">
              <Spinner dark width="20px" height="20px" />
            </Flex>
          </Box>
        )
      case "error":
        return (
          <Box>
            <Text mb={4} fontWeight="bold" fontSize={4}>
              Woops
              <Text fontWeight="normal" fontSize={1}>
                {error}
              </Text>
            </Text>
            <Flex>
              <Flex
                sx={{
                  fontSize: "14px",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                Make sure you are logged in:
                <CodeSnippet mt={2} mb={4} type="cli">
                  medusa login
                </CodeSnippet>
                Link your local project:
                <CodeSnippet mt={2} type="cli">
                  medusa link
                </CodeSnippet>
              </Flex>
            </Flex>
          </Box>
        )
      case "success":
        return (
          <Box>
            <Text mb={4} fontWeight="bold" fontSize={4}>
              Linking Completed
            </Text>
            <Flex>
              <Flex
                sx={{
                  fontSize: "14px",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <p>
                  You can now view and manage the store in your local
                  environment.
                </p>
                <p>
                  Check our{" "}
                  <a target="_blank" href={"https://medusa-commerce.com/docs"}>
                    documentation
                  </a>{" "}
                  for tutorials and guides on how to build amazing digital
                  commerce experiences.
                </p>
                <Button to={"/a/orders"} variant={"cta"}>
                  Go to orders
                </Button>
              </Flex>
            </Flex>
          </Box>
        )
      default:
        return null
    }
  }, [status])

  return (
    <LoginLayout pt={3}>
      <SEO
        title={
          status === "success" ? "Linking complete" : "Linking please wait..."
        }
      />
      {renderContent()}
    </LoginLayout>
  )
}

export default IndexPage
